import { SET_CALENDAR_ITEMS, SET_DATE_EVENTS, SET_FAILURE_CALENDAR, SET_LOADING_CALENDAR, SET_INITIAL_DATE_EVENTS, SET_START_END_DATE_EVENTS, ADD_TO_CALENDAR_ITEMS, SET_FAILURE_CALENDAR_DATE_EVENTS, SET_LOADING_CALENDAR_DATE_EVENTS, SET_DAY_EVENTS_TO_EVENTS_CALENDAR, SET_EVENTS_CALENDAR_STATE, SET_LOADING_EVENTS, SET_FAILURE_EVENTS, SET_EVENTS_LIST, SET_EVENTS_LIST_SORTED, SET_PAGINATION_EVENTS_LIST, SET_LOADMORE_LOADING_EVENTS, SET_LOADMORE_ERROR_EVENTS, ADD_NEW_EVENTS_TO_LIST, ADD_NEW_EVENTS_TO_DATE_CALENDAR, SET_FILTER_EVENTS, SET_FILTER_ERROR_EVENTS, SET_FILTER_SORT_EVENTS, SET_CAN_I_SEND_DATA_EVENTS, SET_ENTERED_DATA_EVENTS, SET_FIELDS_EVENTS, ADD_ITEM_TO_LIST_EVENTS, SET_HAVE_CHANGE_IN_STORAGE, DELETE_ITEM_EVENT, DELETE_ITEM_EVENT_DAY, SET_DELETE_ITEM_STATUS_EVENTS, SET_EVENT_DATA_LOADING, SET_EVENT_DATA_VALUES, SET_EVENT_DATA_ERROR, EDIT_ITEM_EVENT, SET_SERVER_DATE_OBJECT, SET_COMPUTER_DIFFERRNTTIME, RESTE_ALL_EVENTS_DATA_APP, ADD_EVENTS_TO_FILTER_EVENTS } from "../../action/events/eventsType"

const initalState = {
    events: [],
    loading: false,
    error: null,
    loaded: false,
    eventsSorted : {},
    pagination: {},
    loadMoreLoading: false,
    loadMoreError: null,

    eventsCalendarState: true,

    // date info
    date: '',
    initialDate: '',
    startDate: '',
    endDate: '',
    serverDateObject: { },
    computerDifferentTime: null,

    // calendar info
    calendarItems: {},
    calendarLoaded: false,
    calendarLoading: false,
    calendarError: null,

    eventsDay: {},
    eventsDayLoading: false, 
    eventsDayError: null, 
    loadMoreEventDayLoading: false,

    filtered: {
        searchedValue: '',
        list: [],
        pagination: {}
    },
    filterSorted: {},
    filterError: null,

    // add & update 
    enteredData: false,
    canISendData: false,
    fields: {
        title: {value: '', max: 50, isValid: true, prefix: ''},
        showEventToMovakel: false,
        type: '',
        caseInfo: {},
        description: {value: '', min: 3, isValid: true},
        alaram_at: {
            clock: '',
            date: '',
            label: '',
            englishDate: ''
        },
        clock: '',
        sendSms: true,
    },
    haveAChangeInStorage: {
        list: false,
        calendar: false,
        eventDate: false,
    },
    eventData: {},
    eventDataLoading: false,
    eventDataError: null,
    
    deletedItem: null 
}

const eventsReducer = (state = initalState, action) => {

    switch(action.type){

        
        case SET_LOADING_EVENTS :
            return {
                ...state,
                loading: action.payload,
            }

        case SET_FAILURE_EVENTS :
            return {
                ...state,
                loading: false,
                error: action.payload,
                eventDataLoading: false
            }

        case SET_EVENTS_LIST :
            return {
                ...state,
                loading: false,
                error: null,
                events: action.payload,
                loaded: true
            }

        case SET_EVENTS_LIST_SORTED :
            return {
                ...state,
                eventsSorted : action.payload,
            }

        case ADD_ITEM_TO_LIST_EVENTS :
            return {
                ...state,
                haveAChangeInStorage: {
                    list: true,
                    calendar: true,
                    eventDate: true,
                },
                loading: false,
                error: null,
            }

        case SET_PAGINATION_EVENTS_LIST :
            return {
                ...state,
                pagination: action.payload
            }

        case SET_LOADMORE_LOADING_EVENTS :
            return {
                ...state,
                loadMoreLoading: action.payload
            }

        case SET_LOADMORE_ERROR_EVENTS :
            return {
                ...state,
                loadMoreError: action.payload,
                loadMoreLoading: false
            }
        
        case ADD_NEW_EVENTS_TO_LIST :
            return {
                ...state,
                loadMoreError: null,
                loadMoreLoading: false,
                pagination: action.payload.pagination,
                events: [
                    ...state.events,
                    ...action.payload.data
                ]
            }

        case ADD_EVENTS_TO_FILTER_EVENTS :
            return {
                ...state,
                loadMoreError: null,
                loadMoreLoading: false,
                filtered: {
                    searchedValue: action.payload.value,
                    list: [
                        ...state.filtered.list,
                        ...action.payload.list
                    ],
                    pagination: action.payload.pagination
                }
            }

        case SET_DATE_EVENTS :
            return {
                ...state,
                date: action.payload
            }

        case SET_INITIAL_DATE_EVENTS :
            return {
                ...state,
                initialDate: action.payload
            }
        
        case SET_START_END_DATE_EVENTS :
            return {
                ...state,
                startDate: action.payload.start,
                endDate: action.payload.end
            }

        case SET_CALENDAR_ITEMS :
            return {
                ...state,
                calendarItems: action.payload,
                calendarLoaded: true,
                calendarLoading: false
            }

        case SET_LOADING_CALENDAR :
            return {
                ...state,
                calendarLoading: action.payload
            }

        case SET_FAILURE_CALENDAR : 
            return {
                ...state,
                calendarError: action.payload,
                calendarLoading: false
            }

        case ADD_TO_CALENDAR_ITEMS :
            return {
                ...state,
                calendarItems: {
                    ...state.calendarItems,
                    ...action.payload
                },
                calendarLoading: false
            }

        case SET_LOADING_CALENDAR_DATE_EVENTS :
            return {
                ...state,
                eventsDayLoading: action.payload
            }

        case SET_FAILURE_CALENDAR_DATE_EVENTS :
            return {
                ...state,
                eventsDayLoading: false,
                eventsDayError: action.payload
            }

        case SET_DAY_EVENTS_TO_EVENTS_CALENDAR :
            return {
                ...state,
                eventsDayLoading: false,
                eventsDayError: null,
                eventsDay: action.payload
            }
        
        case ADD_NEW_EVENTS_TO_DATE_CALENDAR :
            return {
                ...state,
                loadMoreLoading: false,
                loadMoreError: null,
                eventsDay: {
                    [action.payload.date]: [
                        ...state.eventsDay[[action.payload.date]],
                        ...action.payload.data,
                    ],
                    pagination: action.payload.pagination
                }
            }

        case SET_EVENTS_CALENDAR_STATE : 
            return {
                ...state,
                eventsCalendarState: action.payload
            }


        case SET_FILTER_EVENTS :
            return {
                ...state,
                loading: false,
                filtered: {
                    searchedValue: action.payload.value,
                    list: action.payload.list,
                    pagination: action.payload.pagination
                },
                filterError: null,
            }
            
        case SET_FILTER_ERROR_EVENTS :
            return {
                ...state,
                filterError: action.payload,
                loading: false
            }

        case SET_FILTER_SORT_EVENTS : 
            return {
                ...state,
                filterSorted: action.payload
            }


        case SET_CAN_I_SEND_DATA_EVENTS :
            return {
                ...state,
                canISendData: action.payload
            }

        case SET_ENTERED_DATA_EVENTS :
            return {
                ...state,
                enteredData: action.payload
            }

        case SET_FIELDS_EVENTS :
            return {
                ...state,
                fields: action.payload
            }

        case SET_HAVE_CHANGE_IN_STORAGE :
            return {
                ...state,
                haveAChangeInStorage: action.payload
            }

        case DELETE_ITEM_EVENT :
            return {
                ...state,
                events: [
                    ...state.events.filter(item => item.id != action.payload)
                ],
                haveAChangeInStorage: {
                    list: false,
                    calendar: true,
                    eventDate: true,
                },
                filtered: {
                    searchedValue: state.filtered.searchedValue,
                    list: [
                        ...state.filtered.list.filter(item => item.id != action.payload)
                    ],
                    pagination: state.filtered.pagination
                },
                loading: false,
                error: null,
                deletedItem: true
            }

        case DELETE_ITEM_EVENT_DAY :
            return {
                ...state,
                events: [
                    ...state.events.filter(item => item.id != action.payload.id)
                ],
                eventsDay: { 
                    [action.payload.date]: state?.eventsDay[action.payload.date]?.filter(item => item.id != action.payload.id)
                },
                filtered: {
                    searchedValue: state.filtered.searchedValue,
                    list: [
                        ...state.filtered.list.filter(item => item.id != action.payload.id)
                    ],
                    pagination: state.filtered.pagination
                },
                haveAChangeInStorage: {
                    list: false,
                    calendar: true,
                    eventDate: false,
                },
                loading: false,
                error: null,
                deletedItem: true,
            }

        case SET_DELETE_ITEM_STATUS_EVENTS :
            return {
                ...state,
                deletedItem: action.payload
            }

        
        case SET_EVENT_DATA_LOADING :
            return {
                ...state,
                eventDataLoading: action.payload
            }

        case SET_EVENT_DATA_ERROR :
            return {
                ...state,
                eventDataLoading: false,
                eventDataError: action.payload
            }

        case SET_EVENT_DATA_VALUES :
            return {
                ...state,
                eventDataLoading: false,
                eventData: {
                    ...state.eventData,
                    [action.payload.id]: action.payload.list
                },
                eventDataError: null,
                error: null
            }

        case EDIT_ITEM_EVENT :
            return {
                ...state,
                eventData: {
                    ...state.eventData,
                    [action.payload.id]: {
                        ...state.eventData[action.payload.id],
                        title : action.payload.value.title,
                        description : action.payload.value.description,
                        type : action.payload.value.type,
                        is_public : action.payload.value.is_public,
                        alarm_at : action.payload.value.event_alaram_at,
                        starts_at : action.payload.value.event_date,
                    }
                },
                haveAChangeInStorage: {
                    list: true,
                    calendar: true,
                    eventDate: true,
                },
                loading: false,
                error: null,  
            }

        case SET_SERVER_DATE_OBJECT :
            return {
                ...state,
                serverDateObject: action.payload,
            }

        case SET_COMPUTER_DIFFERRNTTIME :
            return {
                ...state,
                computerDifferentTime: action.payload
            }

        case RESTE_ALL_EVENTS_DATA_APP :
            return {
                ...state,
                ...initalState
            }


        default : 
            return state

    }

}

export default eventsReducer